
$(function () {
    updateScrollTopOffset();
    doEventPeriodically($(window), "resize", 100, updateScrollTopOffset);

    if ($("#imarcomGmap").length) {
        let $map = $("#imarcomGmap").imarcomGmap()
        let API = $map.data("API");

        $('.js-contact-marker').on('click', function () {
            if (!$(this).hasClass('contact-marker-active')) {
                $('.contact-marker-active').each(function () {
                    let city = $(this).data('city')
                    API.updateFilters({
                        city: city
                    })
                    $(this).removeClass('contact-marker-active')
                })

                let city = $(this).data('city')
                API.updateFilters({
                    city: city
                })
                $(this).addClass('contact-marker-active')
            }
        })
    }

    if ($(".publicForm:not(.publicForm-proRegister)").length) {
        $(".publicForm:not(.publicForm-proRegister)").publicForm()
    }

    if ($(".publicForm-proRegister").length) {
        $(".publicForm-proRegister .has-error .form-control").keypress(function () {
            $(this).closest('.has-error').removeClass('has-error').find('.error').remove()
        })

        if ($(".publicForm-proRegister .has-error").length) {
            scrollToPosition($(".publicForm-proRegister .has-error").offset().top, {
                speed: 800
            });
        }
    }

    if ($(".imarcomSlideshow").length) {
        $(".imarcomSlideshow").each(function () {
            if ($(this).data('autoswap') !== undefined) {
                $(".imarcomSlideshow").imarcomSlideshow({
                    arrows_display: true,
                    auto_swap: $(this).data('autoswap')
                });
            } else {
                $(".imarcomSlideshow").imarcomSlideshow({
                    arrows_display: true
                });
            }
        });
    }

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
})
